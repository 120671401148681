html, body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.App {
  width: 100%;
  height: 100%;
  margin: 0;
}

.App-header {
  padding: 1em;
}

.tasquet-logo {
  height: 3em;
  max-width: 100%;
}

.editor-container {
  padding: 0 1em
}
